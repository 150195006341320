export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.server) return

  const nav = useNavStore();
  // console.log('middlwae client', nav.clientSide)
  if (nav.clientSide) {
    return;
  }
  setTimeout(() => {
    nav.setClientSide(true);
  }, 200)
});
